/* font */
/* nanusquare neo */
@font-face {
  font-family: "Nanum";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/NanumSquareNeoTTF-aLt.woff2") format("woff2");
}
@font-face {
  font-family: "Nanum";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/NanumSquareNeoTTF-bRg.woff2") format("woff2");
}
@font-face {
  font-family: "Nanum";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/NanumSquareNeoTTF-cBd.woff2") format("woff2");
}
@font-face {
  font-family: "Nanum";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/NanumSquareNeoTTF-dEb.woff2") format("woff2");
}
@font-face {
  font-family: "Nanum";
  font-style: normal;
  font-weight: 800;
  src: url("./assets/fonts/NanumSquareNeoTTF-eHv.woff2") format("woff2");
}

/* noto sans Kr */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("./assets/fonts/NotoSansKR-Light.woff2") format("woff2");
}
@font-face {
  font-family: "NotoSansKR_C";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/NotoSansKR-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "NotoSansKR_C";
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/NotoSansKR-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "NotoSansKR_C";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/NotoSansKR-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "NotoSansKR_C";
  font-style: normal;
  font-weight: lighter;
  src: url("./assets/fonts/NotoSansKR-Thin.woff2") format("woff2");
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Nanum", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 25px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: 100vw;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: pan-x pan-y;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .ytp-chrome-top {
  display: none !important;
}
.bgVideo .ytp-chrome-top {
  display: none !important;
} */
